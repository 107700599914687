var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "top_box"
  }, [_c('div', {
    staticClass: "login_title",
    on: {
      "click": _vm.goback
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('set.adviceFeedback')))]), _c('img', {
    attrs: {
      "src": require("../../../assets/mycenter/jiantou.png"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "login_title_1"
  }, [_vm._v(" " + _vm._s(_vm.$t('set.Historical_feedback')))])]), _c('div', {
    staticClass: "table_box"
  }, [_c('el-table', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "data": _vm.feedBackList,
      "border": ""
    }
  }, [_c('el-table-column', {
    attrs: {
      "type": "index",
      "label": "ID",
      "width": "88",
      "align": "center",
      "class-name": "table_id"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "type",
      "label": "反馈类型",
      "width": "180",
      "align": "center"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "图片",
      "align": "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return _vm._l(scope.row.thumb, function (item, index) {
          return _c('img', {
            key: index,
            staticStyle: {
              "width": "150px",
              "height": "150px"
            },
            attrs: {
              "src": item,
              "alt": ""
            }
          });
        });
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "replay",
      "label": "平台回复",
      "align": "center"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "create_time",
      "label": "反馈时间",
      "align": "center"
    }
  })], 1)], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };