export default {
  data() {
    return {
      feedBackList: [],
      tableData: [{
        date: '1',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1517 弄'
      }, {
        date: '3',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1519 弄'
      }, {
        date: '4',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1516 弄'
      }]
    };
  },
  mounted() {
    this.getSuggestions();
  },
  methods: {
    backClick() {
      this.$router.back(-1);
    },
    getSuggestions() {
      this.$http.suggestionsList().then(res => {
        console.log(res);
        if (res.code == 1) {
          this.feedBackList = res.data;
        } else {
          this.$message.info(res.msg);
        }
      });
    },
    goback() {
      this.$router.go(-1);
    }
  }
};